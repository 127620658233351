import React from 'react'
import { GoArrowUpRight } from 'react-icons/go'

const button = ({content}:any) => {
  return (
    <button className={`text-pinkdarker text-[14px]  lg:text-[16px]  2xl:text-[18px] bg-white gap-3 flex items-center rounded-full p-[2px] mb-4  border-lightpink border-[2px]
    font-medium  group hover:bg-lightpink transition-all duration-300`}>
             <span className="min-w-[45px] h-[45px] flex bg-lightpink justify-center items-center rounded-full
           border-lightpink border-[1px] transform transition-transform duration-300 group-hover:bg-pinkdarker  group-hover:rotate-45 group-hover:translate-x-2">
                 <GoArrowUpRight className="-rotate-30 group-hover:text-white text-[28px] text-pinkdarker" />
             </span>
             <span className="transform pr-4 md:pr-6 text-left transition-transform  duration-300 group-hover:translate-x-2">
                 {content} 
                 </span>
    </button >
  )
}
export default button